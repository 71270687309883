<template>
  <DialogFormWrapper>
    <template #form>
      <v-form
        id="updateDialogForm"
        ref="updateOrderForm"
        class="custom-form"
        @submit.prevent="updateValue()"
      >
        <v-row>
          <v-col
            cols="12"
            class="pb-0"
          >
            <DateWithTimePicker
              :date-with-time="plannedTransportTime"
              label="Planowany czas transportu"
              @changeTime="plannedTransportTime = $event"
            />
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template #submit>
      <v-btn
        color="primary"
        class="base-hover"
        :loading="isProcessing"
        type="submit"
        form="updateDialogForm"
      >
        Zapisz czas
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import rules from '../../utils/validators'
import DialogFormWrapper from './Partials/DialogFormWrapper'
import DateWithTimePicker from './../Forms/Inputs/DateWithTime'
import afterFormSubmitted from '../../mixins/afterFormSubmitted'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    DialogFormWrapper,
    DateWithTimePicker,
  },
  mixins: [afterFormSubmitted],
  data () {
    return {
      rules,
      plannedTransportTime: null
    }
  },
  computed: {
    ...mapState({
      bdoCard: state => state.bdoCard.entity,
      isProcessing: state => state.bdoCard.isProcessing
    }),
  },
  created () {
    this.plannedTransportTime = this.bdoCard?.plannedTransportTime
  },
  methods: {
    ...mapActions({
      closeDialog: 'layout/closeDialog',
      updateTransportTime: 'bdoCard/updateTransportTime'
    }),
    updateValue () {
      if (this.$refs.updateOrderForm.validate()) {
        this.updateTransportTime(this.plannedTransportTime)
          .then(() => {
            this.afterFormSubmitted('Zmieniono czas transportu')
          })
      }
    }
  }
}
</script>
